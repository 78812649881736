export default Object.freeze({
  // MASTER_LOGIN_API: process.env.VUE_APP_LOCAL_MASTER_LOGIN_API,
  // MASTER_LOGIN_API: process.env.VUE_APP_STAGING_MASTER_LOGIN_API,
  MASTER_LOGIN_API: process.env.VUE_APP_MASTER_LOGIN_API,
  // COMPANY_LOGIN_API: process.env.VUE_APP_LOCAL_COMPANY_LOGIN_API,
  // COMPANY_LOGIN_API: process.env.VUE_APP_STAGING_COMPANY_LOGIN_API,
  COMPANY_LOGIN_API: process.env.VUE_APP_COMPANY_LOGIN_API,
  // BASE_API: process.env.VUE_APP_LOCAL_BASE_API,
  // BASE_API: process.env.VUE_APP_STAGING_BASE_API,
  BASE_API: process.env.VUE_APP_BASE_API,
  USER_TYPE_ADMIN: '1',
  USER_TYPE_SUPER_ADMIN: '10',
  USER_TYPE_USER: '1',
  COMPANY_USER_TYPE_USER: '2',
  USER_STATUS_ACTIVE: 'A',
  USER_STATUS_INACTIVE: 'I',
  SUCCESS: '200',
  ERROR_422: '422',
  ERROR: '201',
  SUPER_ADMIN_FORGOT_PASSWORD: 'admin-forgot-password',
  USER_FORGOT_PASSWORD: 'auth-forgot-password',
  ERROR_500: '500',
  DEFAULT_TITLE: 'IMS',
  // API Route Prefix
  COMPANY_API_PREFIX: 'company/v1',
  WEB_API_PREFIX: 'web/v1',
  ADMIN_API_PREFIX: 'admin/v1',

  ASSIGN_SERVICE_LEAD: '2003',

  // Activities API Paramerters
  LEAD: '1',
  VENDOR: '2',
  VENDOR_UNIT: '3',
  OPPORTNUITY: '4',

  ASSIGNED_PARAM_VALUE: 2,
  PENDING_PARAM_VALUE: 1,

  EDIT_LEAD: '2006',
  MANAGE_GROUP_RIGHTS: '1006',

  LEAD_STATUS_ASSIGNED: '3002',
  LEAD_STATUS_FAILURE: '3004',

  ACTIVE_OPPORTUNITY_STATUS: [1009, 1010, 1012],
  ATTACHMENT_REQ_STAGES: [1005, 1007],

  LIST_TEMPLATE: '4001',
  ADD_TEMPLATE: '4002',
  EDIT_TEMPLATE: '4003',

  TEMPLATE_QUESTION: '5001',
  ADD_TEMPLATE_QUESTION: '5002',
  EDIT_TEMPLATE_QUESTION: '5003',

  ADD_ROLE: '1008',
  EDIT_ROLE: '1009',

  IS_ASSIGNED: 2,
  ALL_SERVICES_ASSIGNED: 3,

  // Constant Texts
  CHANGE_STATUS_ACTIVITY_MODAL_TITLE: 'Change Status Activity',
  ADD_REMARK_TEXT: 'Add Remark',
  OTHER_OPTIONS_FOR_SERVICE: 'Others',

  SHA256_ENCRYPT_KEY: '9XTMgu0h0R14lm8vO2cQYG8SFMe4A50j',

  // Template Group Ids from Inputs
  TEMPLATE_GROUP_TEXT: 4004,
  TEMPLATE_GROUP_SELECT: 4001,
  TEMPLATE_GROUP_CHECKBOX: 4002,
  TEMPLATE_GROUP_RADIO: 4003,
  TEMPLATE_GROUP_FILE: 4005,
  TEMPLATE_GROUP_REPEATER: 4006,
  TEMPLATE_GROUP_INNER_REPEATER: 4007,
  TEMPLATE_GROUP: [4004, 4005, 4006, 4007, 4008],
  TEMPLATE_GROUP_FILE_TEXT: 'file',
  TEMPLATE_GROUP_INPUT_TEXT: 'text',
  TEMPLATE_GROUP_FROM_MASTER: '1',

  OPPORTNUITY_STATUS_INFO_RECIEVED: 1003,
  LOSE_CUSTOMER_ID: 1010,
  OTHER_SERVICE_ID: 17,
  REVENUE_ON_BOARDED: 1008,
  importExcelProperty: [
    'state',
    'Flexible Qty',
    'Flexible Rate',
    'Flexible Total',
    'Rigid Qty',
    'Rigid Rate',
    'Rigid Total',
    'MLP Qty',
    'MLP Rate',
    'MLP Total',
  ],
  typeList: [
    { label: 'Prospact', id: 'Prospact' },
    { label: 'Existing', id: 'Existing' },
  ],
  PWM_Rules: [
    { label: 'Producer', id: 'Producer' },
    { label: 'Brand Owner', id: 'Brand_Owner' },
    { label: 'Importer', id: 'Importer' },
    { label: 'Plastic Waste Processor', id: 'Plastic_Waste_Processor' },
  ],
  FinancialYearList: [
    { label: '2023-24', id: '2023-24' },
    { label: '2024-25', id: '2024-25' },
    { label: '2025-26', id: '2025-26' },
    { label: '2026-27', id: '2026-27' },
    { label: '2027-28', id: '2027-28' },
    { label: '2028-29', id: '2028-29' },
    { label: '2029-30', id: '2029-30' },
  ],
  StatusList: [
    { label: 'Active', id: 1 },
    { label: 'In-Active', id: 0 },
  ],
})
